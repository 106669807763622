import React, { useEffect, useState } from 'react';
import { transform } from '@babel/standalone';

interface DynamicComponentLoaderProps {
    componentName: string;
    props?: Record<string, any>;
}

const DynamicComponentLoader: React.FC<DynamicComponentLoaderProps> = ({ componentName, props = {} }) => {
    const [Component, setComponent] = useState<React.ComponentType<any> | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadComponent = async () => {
            try {
                // Fetch the component code using the query parameter 'name'
                const response = await fetch(`/api/comps?name=${encodeURIComponent(componentName)}`);
                if (!response.ok) throw new Error('Component not found');

                // Assuming the API returns JSON with a 'code' field
                // @ts-ignore
                const { code } = await response.json();

                // Compile the code using Babel with TypeScript and JSX support
                const transformedCode = transform(code, {
                    filename: 'file.tsx', // Add the filename option
                    presets: ['react', 'env', 'typescript'], // Include 'typescript' preset
                })?.code;

                if (!transformedCode) {
                    throw new Error('Failed to transform component code.');
                }

                // Prepare a module-like environment
                const exports: Record<string, any> = {};
                const require = (moduleName: string) => {
                    if (moduleName === 'react') return React;
                    throw new Error(`Cannot find module '${moduleName}'`);
                };

                // Execute the transformed code
                // eslint-disable-next-line no-new-func
                const func = new Function('React', 'require', 'exports', transformedCode);
                func(React, require, exports);

                // Retrieve the exported component
                const LoadedComponent = exports.default || exports;

                if (!LoadedComponent) {
                    throw new Error('Failed to load the component.');
                }

                setComponent(() => LoadedComponent);
            } catch (err: any) {
                console.error(err);
                setError(err.message || 'Unknown error occurred.');
            }
        };

        loadComponent();
    }, [componentName]);

    if (error) return <div>Error: {error}</div>;
    if (!Component) return <div>Loading...</div>;
    return <Component {...props} />;
};

export default DynamicComponentLoader;

import React, {useState, useEffect} from 'react';
import {
    Box,
    VStack,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
    Select,
    useToast,
} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import personaServices from '../services/PersonaServices';
import {Persona} from '../types';
import {Container} from '@chakra-ui/react';

// Define provider and model options
const PROVIDER_OPTIONS = ['groq', 'cerebras'] as const;
type Provider = typeof PROVIDER_OPTIONS[number];

const MODEL_OPTIONS: Record<Provider, string[]> = {
    groq: [
        'gemma-7b-it',
        'gemma2-9b-it',
        'llama-3.1-70b-versatile',
        'llama-3.1-8b-instant',
        'llama-guard-3-8b',
        'llama3-70b-8192',
        'llama3-8b-8192',
        'llama3-groq-70b-8192-tool-use-preview',
        'llama3-groq-8b-8192-tool-use-preview',
        'llava-v1.5-7b-4096-preview',
        'mixtral-8x7b-32768'
    ],
    cerebras: [
        'llama3.1-8b',
        'llama3.1-70b'
    ]
};

// Define props interface to accept personaData
interface CreatePersonaProps {
    personaData?: Partial<Persona>;
}

const CreatePersona: React.FC<CreatePersonaProps> = ({personaData}) => {
    const navigate = useNavigate();
    const toast = useToast();
    const isUpdating = !!personaData;

    // Initialize state with personaData if available, otherwise use defaults
    const [persona, setPersona] = useState<Omit<Persona, 'id' | 'created_at' | 'updated_at'>>({
        version: '0.1',
        name: '',
        author: '',
        desc: '',
        html_desc: '',
        html: '',
        bootstrap_version: 'web1',
        modelName: '',
        provider: PROVIDER_OPTIONS[0],
        systemMessage: 'You are a helpful assistant.',
        ...personaData,
    });

    const [isGeneratingSystemMessage, setIsGeneratingSystemMessage] = useState(false);
    const [isGeneratingHtml, setIsGeneratingHtml] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Set the default model when the provider changes
    useEffect(() => {
        setPersona(prev => ({
            ...prev,
            modelName: MODEL_OPTIONS[prev.provider as Provider][0]
        }));
    }, [persona.provider]);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const {name, value} = e.target;
        setPersona(prev => ({...prev, [name]: value}));
    };

    const handleAutoGenerateSystemMessage = async () => {
        if (!persona.desc) {
            toast({
                title: 'Missing description',
                description: 'Please provide a persona description before auto-generating the system message.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
            return;
        }

        setIsGeneratingSystemMessage(true);
        try {
            const response = await fetch('/api/generateSystemMessage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({description: persona.desc}),
            });

            if (!response.ok) {
                throw new Error('Failed to generate system message');
            }

            const data: unknown = await response.json();

            if (
                typeof data === 'object' &&
                data !== null &&
                'systemMessage' in data &&
                typeof (data as { systemMessage: unknown }).systemMessage === 'string'
            ) {
                setPersona(prev => ({
                    ...prev,
                    systemMessage: (data as { systemMessage: string }).systemMessage
                }));
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: error instanceof Error ? error.message : 'Failed to auto-generate system message',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
        } finally {
            setIsGeneratingSystemMessage(false);
        }
    };

    const handleHtmlGenerate = async () => {
        if (!persona.html_desc) {
            toast({
                title: 'Missing HTML Description',
                description: 'Please provide an HTML description before generating HTML.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
            return;
        }

        setIsGeneratingHtml(true);
        try {
            const response = await fetch('/api/generateHtml', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({description: persona.html_desc}),
            });

            if (!response.ok) {
                throw new Error('Failed to generate HTML');
            }

            const data: unknown = await response.json();

            if (
                typeof data === 'object' &&
                data !== null &&
                'html' in data &&
                typeof (data as { html: unknown }).html === 'string'
            ) {
                setPersona(prev => ({
                    ...prev,
                    html: (data as { html: string }).html
                }));
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: error instanceof Error ? error.message : 'Failed to generate HTML',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
        } finally {
            setIsGeneratingHtml(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const {
            name,
            author,
            desc,
            html_desc,
            html,
            modelName,
            provider,
            systemMessage,
            version,
            bootstrap_version,
        } = persona;

        // Validate required fields
        if (!name || !author || !desc || !html_desc || !html || !modelName || !provider || !systemMessage) {
            toast({
                title: 'Missing information',
                description: 'Please fill in all required fields',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
            return;
        }

        setIsLoading(true);
        try {
            const now = Date.now();
            const personaToSave: Persona = {
                ...persona,
                id: isUpdating ? personaData?.id! : `${name}_${author}_${version}`,
                created_at: isUpdating ? personaData?.created_at! : now,
                updated_at: now,
            };

            let response;
            if (isUpdating) {
                response = await personaServices.updatePersona(personaToSave);
            } else {
                response = await personaServices.postPersona(personaToSave);
            }

            if (response && response.id) {
                toast({
                    title: isUpdating ? 'Persona updated' : 'Persona created',
                    description: `Your persona has been ${isUpdating ? 'updated' : 'created'} successfully with ID: ${response.id}`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
                navigate('/personas');
            } else {
                throw new Error(`Persona ${isUpdating ? 'update' : 'creation'} failed: No ID returned`);
            }
        } catch (error) {
            console.error(`Error ${isUpdating ? 'updating' : 'creating'} persona:`, error);
            toast({
                title: `Error ${isUpdating ? 'updating' : 'creating'} persona`,
                description: error instanceof Error ? error.message : 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxW="container.lg" p={0} >

            <Box maxW="600px" mx="auto" py={6} px={4}>
                <Heading as="h1" size="xl" textAlign="center" mb={6}>
                    {isUpdating ? 'Update Persona' : 'Create a New Persona'}
                </Heading>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4} align="stretch">
                        {/* Persona Name */}
                        <FormControl id="name" isRequired>
                            <FormLabel>Persona Name</FormLabel>
                            <Input
                                type="text"
                                name="name"
                                placeholder="Enter persona name"
                                value={persona.name}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        {/* Author Name */}
                        <FormControl id="author" isRequired>
                            <FormLabel>Author Name</FormLabel>
                            <Input
                                type="text"
                                name="author"
                                placeholder="Enter author name"
                                value={persona.author}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        {/* Description */}
                        <FormControl id="desc" isRequired>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                name="desc"
                                placeholder="Enter description"
                                value={persona.desc}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        {/* HTML Description */}
                        <FormControl id="html_desc" isRequired>
                            <FormLabel>HTML Description</FormLabel>
                            <Textarea
                                name="html_desc"
                                placeholder="Enter HTML description"
                                value={persona.html_desc}
                                onChange={handleInputChange}
                            />
                            <Button
                                onClick={handleHtmlGenerate}
                                mt={2}
                                isLoading={isGeneratingHtml}
                                loadingText="Generating..."
                                colorScheme="teal"
                                size="sm"
                            >
                                Generate HTML
                            </Button>
                        </FormControl>

                        {/* Generated HTML */}
                        <FormControl id="html" isRequired>
                            <FormLabel>Generated HTML</FormLabel>
                            <Textarea
                                name="html"
                                placeholder="Generated HTML will appear here"
                                value={persona.html}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        {/* Provider */}
                        <FormControl id="provider" isRequired>
                            <FormLabel>Provider</FormLabel>
                            <Select
                                name="provider"
                                value={persona.provider}
                                onChange={handleInputChange}
                            >
                                {PROVIDER_OPTIONS.map((option) => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Model Name */}
                        <FormControl id="modelName" isRequired>
                            <FormLabel>Model Name</FormLabel>
                            <Select
                                name="modelName"
                                value={persona.modelName}
                                onChange={handleInputChange}
                            >
                                {MODEL_OPTIONS[persona.provider as Provider].map((option) => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </Select>
                        </FormControl>

                        {/* System Message */}
                        <FormControl id="systemMessage" isRequired>
                            <FormLabel>System Message</FormLabel>
                            <Textarea
                                name="systemMessage"
                                placeholder="Enter system message or use Auto Generate"
                                value={persona.systemMessage}
                                onChange={handleInputChange}
                            />
                            <Button
                                onClick={handleAutoGenerateSystemMessage}
                                mt={2}
                                isLoading={isGeneratingSystemMessage}
                                loadingText="Generating..."
                                colorScheme="blue"
                                size="sm"
                            >
                                Auto Generate
                            </Button>
                        </FormControl>

                        {/* Version */}
                        <FormControl id="version">
                            <FormLabel>Version</FormLabel>
                            <Input
                                type="text"
                                name="version"
                                placeholder="Enter version"
                                value={persona.version}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        {/* Bootstrap Version */}
                        <FormControl id="bootstrap_version">
                            <FormLabel>Bootstrap Version</FormLabel>
                            <Select
                                name="bootstrap_version"
                                value={persona.bootstrap_version}
                                onChange={handleInputChange}
                            >
                                <option value="web1">Web1</option>
                                <option value="web2">Web2</option>
                                <option value="web3">Web3</option>
                            </Select>
                        </FormControl>

                        {/* Submit Button */}
                        <Button
                            type="submit"
                            colorScheme="orange"
                            size="lg"
                            width="full"
                            isLoading={isLoading}
                            loadingText={isUpdating ? "Updating" : "Creating"}
                        >
                            {isUpdating ? 'Update Persona' : 'Create Persona'}
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Container>
    );
};

export default CreatePersona;
// src/components/PersonaRenderer.tsx

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Skeleton,
    useToast,
    Flex,
    Container,
} from '@chakra-ui/react';
import PersonaServices from '../services/PersonaServices';
import { streamComment } from '../utils/StreamingUtils';
import { Persona, ChatMessage } from '../types';
import parse, { DOMNode } from 'html-react-parser';
import DynamicComponentLoader from '../utils/DynamicComponentLoader';

interface ExtendedPersonaData extends Persona {
    htmlStructure: string;
}

const PersonaRenderer: React.FC = () => {
    const { personaName, author, version } = useParams<{ personaName: string; author: string; version: string }>();
    const [personaData, setPersonaData] = useState<ExtendedPersonaData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isStreaming, setIsStreaming] = useState(false);
    const toast = useToast();

    // Refs for streaming content
    const streamedContentRef = useRef<string>('');

    const personaId = useMemo(() => `${personaName}_${author}_${version}`, [personaName, author, version]);
    const storageKey = useMemo(() => `chat_history_${personaId}`, [personaId]);
    const streamingKey = useMemo(() => `main_content_${personaId}`, [personaId]);

    // Load persona data
    useEffect(() => {
        const fetchPersonaData = async () => {
            if (!personaId) return;
            try {
                const data = await PersonaServices.getPersona(personaId, 'web1'); // Fetch for bootstrap version 3
                setPersonaData(data as ExtendedPersonaData);
            } catch (error) {
                console.error('Error fetching persona data:', error);
                toast({
                    title: 'Error',
                    description: 'Failed to fetch persona data',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };
        fetchPersonaData();
    }, [personaId, toast]);

    const handleSend = useCallback(async (input: string) => {
        if (isStreaming || !personaData) return;

        if (!input.trim()) {
            toast({
                title: 'Input Required',
                description: 'Please enter a message before sending.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
            return;
        }

        setIsStreaming(true);
        streamedContentRef.current = '';

        const userMessage: ChatMessage = { role: 'user', content: input };
        let updatedHistory: ChatMessage[] = [];

        // Retrieve existing history from localStorage
        const storedHistory = localStorage.getItem(storageKey);
        if (storedHistory) {
            try {
                const parsedHistory: ChatMessage[] = JSON.parse(storedHistory);
                updatedHistory = [...parsedHistory, userMessage];
            } catch (error) {
                console.error('Error parsing chat history from localStorage:', error);
                updatedHistory = [userMessage];
            }
        } else {
            updatedHistory = [userMessage];
        }

        // Update localStorage with the new user message
        localStorage.setItem(storageKey, JSON.stringify(updatedHistory));
        console.log('PersonaRenderer: Updated chat history in localStorage:', updatedHistory);

        const messages: ChatMessage[] = [
            { role: 'system', content: personaData.systemMessage },
            ...updatedHistory,
        ];

        try {
            // Streaming the conversation
            await streamComment(
                messages,
                (content) => {
                    streamedContentRef.current += content;
                    // Update localStorage with the streaming content
                    localStorage.setItem(streamingKey, streamedContentRef.current);
                    console.log('PersonaRenderer: Updated streamedContent in localStorage:', streamedContentRef.current);
                    // Dispatch a custom event to notify StreamingContent component
                    window.dispatchEvent(new Event('streamedContentUpdated'));
                },
                (error) => {
                    console.error('Streaming error:', error);
                    toast({
                        title: 'Streaming Error',
                        description: 'Failed to stream response.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                        position: 'top-right',
                    });
                    setIsStreaming(false);
                },
                () => {
                    const botMessage: ChatMessage = { role: 'assistant', content: streamedContentRef.current };
                    const newHistory = [...updatedHistory, botMessage];
                    localStorage.setItem(storageKey, JSON.stringify(newHistory)); // Update localStorage with bot message
                    console.log('PersonaRenderer: Updated chat history with bot message:', newHistory);
                    // Dispatch event to indicate streaming is complete
                    window.dispatchEvent(new Event('streamedContentUpdated'));
                    setIsStreaming(false);
                },
                personaData.id,          // Pass the persona ID
                personaData.provider     // Pass the persona provider
            );
        } catch (error) {
            console.error('Error streaming comment:', error);
            toast({
                title: 'Error',
                description: 'Failed to send message.',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
            });
            setIsStreaming(false);
        }
    }, [isStreaming, personaData, toast, storageKey, streamingKey]);

    // Handle message click from ChatHistory
    const handleMessageClick = (message: ChatMessage) => {
        // For simplicity, store the clicked message content in localStorage
        const clickedMessageContent = message.role === 'assistant' ? message.content : `User: ${message.content}`;
        localStorage.setItem('clicked_message', clickedMessageContent);
        console.log('PersonaRenderer: Clicked message stored in localStorage:', clickedMessageContent);
        // Dispatch a custom event to notify StreamingContent component
        window.dispatchEvent(new Event('clickedMessageUpdated'));
    };

    if (isLoading) return <Skeleton height="200px" />;

    if (!personaData) return <Box>Error loading persona data.</Box>;

    const replaceComponents = (domNode: DOMNode) => {
        if ((domNode as any).type === 'tag' && (domNode as any).attribs && (domNode as any).attribs['data-component']) {
            const componentName = (domNode as any).attribs['data-component'];
            const props = (domNode as any).attribs['data-props'] ? JSON.parse((domNode as any).attribs['data-props']) : {};

            if (componentName === 'CustomTextarea') {
                return (
                    <DynamicComponentLoader
                        componentName={componentName}
                        props={{
                            ...props,
                            onSend: handleSend,
                            isDisabled: isStreaming, // Disable input while streaming
                        }}
                    />
                );
            }

            if (componentName === 'ChatHistory') {
                return (
                    <DynamicComponentLoader
                        componentName={componentName}
                        props={{
                            onMessageClick: handleMessageClick, // Pass the click handler
                        }}
                    />
                );
            }

            return (
                <DynamicComponentLoader
                    componentName={componentName}
                    props={{}}
                />
                // For StreamingContent, do not pass props directly
                // Allow DynamicComponentLoader to handle it based on the data-component attribute
            );
        }
        return undefined; // Let React handle the rest
    };

    // Replace placeholders like {{personaName}} and {{htmlContent}}
    const processedHtml = personaData.html
        .replace('{{personaName}}', personaData.name)
        .replace('{{htmlContent}}', personaData.htmlStructure || '');

    const parsedHtml = parse(processedHtml, {
        replace: replaceComponents,
    });

    return (
        <Container maxW="full" p={0}>
            <Flex height="100vh" width="100%">
                {parsedHtml}
            </Flex>
        </Container>
    );
};

export default PersonaRenderer;
